<template>
    <div :class="{ 'padding-thin': thinPadding }" class="program-project-list-item">
        <div class="inner">
            <div class="overview">
                <Anchor :url="translationFriendlyUrl" class="link">
                    <Typography class="title" as="p" :variant="titleVariant">{{ title }}</Typography>
                </Anchor>

                <RichTextElements :elements="dek" class="dek" bodyStyle="serif-large" />
            </div>
            <AvatarSet
                v-if="contributors?.length"
                :people="contributors"
                :hideNames="true"
                size="default"
                variant="small"
                class="contributors"
                :hideArchived="true"
            />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    dek: {
        type: Object,
        default: () => {},
    },
    contributors: {
        type: Object,
    },
    title: {
        type: String,
    },
    titleVariant: {
        type: String,
    },
    url: {
        type: String,
    },
    thinPadding: {
        type: Boolean,
        default: false,
    },
    pageLanguage: {
        type: String,
        default: '',
    },
});

const translationFriendlyUrl = computed(() => {
    return props.pageLanguage.length ? props.url + '?lang=' + props.pageLanguage : props.url;
});
</script>

<style lang="scss" scoped>
.inner {
    @include content-section;
    @include padding-tb(vertical-space(3));

    @include media-query(tablet-mw) {
        @include grid;
        align-items: center;
        justify-content: space-between;
        gap: var(--col-gutter);
    }

    .padding-thin & {
        padding-top: 0;
        padding-bottom: vertical-space(2);
    }
}

.title {
    color: color(blue, default, 0.8);
}

.dek {
    color: palette(text);
}

.overview {
    @include content-area-padding;
    grid-column: 1 / span 6;
}

.contributors {
    grid-column: 10 / span 3;

    @include media-query(mobile) {
        padding-top: vertical-space(1);
    }

    @include media-query(tablet) {
        padding-inline-start: var(--col-gutter);
    }
}
</style>
